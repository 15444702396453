<template>
  <base-layout />
</template>

<script>
import BaseLayout from "../layouts/BaseLayout";

export default {
  name: "Home",
  components: {
    BaseLayout
  }
};
</script>
