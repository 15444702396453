<template>
  <div v-resize="onResize" class="d-flex flex-column">
    <img
      v-if="!$vuetify.breakpoint.smAndDown && isInRole('patient')"
      lazy-src="/img/LogoAroneSVG.svg"
      :src="logoURL"
      :height="customLogo ? 40 : 70"
      max-width="150"
      alt="logo arone"
      contain
    />
    <a v-else href="/">
      <img
        v-if="!$vuetify.breakpoint.smAndDown"
        :src="logoURL"
        lazy-src="/img/LogoAroneSVG.svg"
        :height="customLogo ? 40 : 70"
        max-width="150"
        alt="logo arone"
        contain
      />
    </a>
    <span
      v-if="customLogo && !$vuetify.breakpoint.smAndDown"
      class="text-white d-inline-flex justify-end align-center"
    >
      <div class="h6 text-white">Powered by</div>
      <img
        class="ml-1"
        v-if="!$vuetify.breakpoint.smAndDown"
        src="/img/LogoAroneSVG.svg"
        height="30"
        alt="poweredBy"
        contain
      />
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      logoURL: undefined,
      logoPending: false
    };
  },
  computed: {
    ...mapGetters("auth", ["isInRole"]),
    customLogo() {
      return this.logoURL != "/img/LogoAroneSVG.svg";
    }
  },
  methods: {
    async onResize() {
      this.logoURL = undefined;
      await this.getLogo();
    },
    async getLogo() {
      if (!this.logoPending) {
        this.logoPending = true;
        const allDocuments = await this.drivers.documentDriver.getAll(
          this.currentStudy
        );
        const logoHash = allDocuments.find(d => d.title == "__logo__")?.hash;

        const { shortToken } = await this.drivers?.client
          ?.get("shorttoken/generate")
          .json();
        this.logoURL = logoHash
          ? `${process.env.VUE_APP_API_URL}/document/${this.currentStudy.name}/${logoHash}/content?shortToken=${shortToken}`
          : "/img/LogoAroneSVG.svg";
        this.logoPending = false;
      }
    }
  }
};
</script>
