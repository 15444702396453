<template>
  <div class="mx-2">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          rounded
          elevation="0"
          :outlined="isAdminPage"
          :color="isAdminPage ? 'danger' : isStudioMode ? 'info' : 'success'"
          :class="isAdminPage ? '' : 'text-white'"
          v-on="on"
          v-bind="attrs"
        >
          <span v-if="!isAdminPage && !isStudioMode">
            <span v-if="!$vuetify.breakpoint.xsOnly">STUDY : </span>
            <strong>{{ currentStudy.name }}</strong>
          </span>
          <span v-else-if="isStudioMode">
            {{ currentStudy.name }} - {{ $t("studio.studioMode") }}
          </span>
          <span v-else> {{ currentStudy.name }} - ADMINISTRATION </span>
          <v-icon class="ml-2">mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list-item-group>
        <v-list-item
          v-for="(study, i) in availableStudies"
          :key="i"
          class="bg-white"
          :href="`/${study}`"
        >
          {{ study }}
        </v-list-item>
      </v-list-item-group>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("auth", ["studies"]),
    isAdminPage() {
      return this.$route.name.includes("Admin");
    },
    availableStudies() {
      return this.studies
        ? Object.keys(this.studies).filter(key =>
            !this.isAdminPage ? this.$route.params.study != key : true
          )
        : [];
    }
  },
  methods: {
    navigateToStudy(studyName) {
      this.$router.push({
        path: `/${studyName}`
      });
    }
  }
};
</script>

<style>
.v-list-item-group {
  max-height: 300px !important;
  overflow-y: auto !important;
}
</style>
