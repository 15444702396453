<template>
  <div class="overflow-hidden">
    <v-app-bar
      color="#2a2f45"
      class="px-sm text-left shadow-sm"
      flat
      :height="mobile ? '80' : '200'"
      absolute
      prominent
    >
      <v-container
        class="right d-flex d-flex-wrap items-center align-center"
        :class="{ 'mt-0 px-0': mobile, 'mt-6': !mobile }"
      >
        <span v-if="!mobile" class="d-inline-flex align-center">
          <adaptive-logo></adaptive-logo>
          <v-chip outlined color="success" class="mx-4"
            ><strong>SPIRAL</strong></v-chip
          >
        </span>

        <v-spacer v-if="!$vuetify.breakpoint.smAndDown" />

        <study-navigator v-if="!mobile"></study-navigator>
        <v-btn v-if="mobile" icon outlined color="white" href="/">
          <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-spacer v-if="mobile"></v-spacer>
        <lang-selector v-if="langs.length > 1"></lang-selector>
        <user-menu></user-menu>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
import LangSelector from "./user/LangSelector.vue";
import UserMenu from "./user/UserMenu.vue";
import StudyNavigator from "./admin/StudyNavigator.vue";
import AdaptiveLogo from "./tools/AdaptiveLogo.vue";
export default {
  components: { LangSelector, UserMenu, StudyNavigator, AdaptiveLogo }
};
</script>
