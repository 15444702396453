<template>
  <div class="col d-inline-flex align-center">
    <div class="mr-3">{{ $t("studioMode") }}</div>
    <v-switch
      :disabled="pendingStudioModification"
      :value="isStudioMode"
      @change="studioMode($event)"
      id="studio-toggle"
    ></v-switch>
  </div>
</template>

<script>
import { PatientState, StudyState } from "spiral-studio";
import studioState from "../../mixin/studioState";
export default {
  computed: {
    isPatientForm() {
      return this.$route.name == "Patient CRF";
    }
  },
  methods: {
    studioMode(event) {
      const studioMode = !!event;
      this.setStudioMode(studioMode);
      const newStudy = this.toggleStudy(studioMode);
      if (this.isPatientForm) this.togglePatient(studioMode, newStudy);
    },
    toggleStudy(studioMode) {
      const newStudy = studioMode
        ? new StudyState(this.currentStudy)
        : this.currentStudy.value;
      this.setStudy(newStudy);
      return newStudy;
    },
    togglePatient(studioMode, newStudy) {
      const newPatient = studioMode
        ? new PatientState(newStudy, this.currentPatient)
        : this.currentPatient.value;
      this.setPatient(newPatient);
      return newPatient;
    }
  },
  mixins: [studioState],
  i18n: {
    messages: {
      en: {
        studioMode: "Studio Mode"
      },
      fr: {
        studioMode: "Mode Studio"
      }
    }
  }
};
</script>

<style></style>
